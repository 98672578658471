<template>
    <div class="container mb-3 card-body">


        <div class="row justify-content-center align-items-center mt-5 mb-5">
            <div class="col-12 col-sm" style="max-width: 600px">

                <div class="mb-3 text-center">
                    <h3 class=""> วิธีชำระเงินด้วย QR code</h3>
                </div>


                <div class="card mb-5">

                    <div class="card-body">
                        <div class="mb-3">
                            <img class="img-fluid mr-1" style="max-height: 40px" src="../assets/image/PromptPay2.png"/>
                            <img class="img-fluid" style="max-height: 40px"
                                 src="../assets/image/Thai_QR_Payment_Logo-01.jpg"/>
                        </div>

                        <ul style=" padding-left: 0; list-style: inside decimal">
                            <li>
                                กดปุ่ม "ยืนยัน" เพื่อรับ QR code
                            </li>
                            <li>
                                สำหรับลูกค้าที่ทำรายการผ่านมือถือให้บันทึกหน้าจอที่มีรูป QR code เก็บไว้
                            </li>

                            <li>
                                เปิดแอพ Mobile Banking เลือกเมนูสแกนพร้อมเพย์ QR code
                            </li>

                            <li>
                                สำหรับลูกค้าที่ทำรายการผ่านมือถือให้กด Import รูปจากอัลบั้ม เลือกรูป QR code
                                ที่บันทึกไว้
                            </li>

                            <li>
                                ตรวจสอบยอด และกด "ยืนยัน" เพื่อชำระเงิน
                            </li>

                            <li>
                                หากรายการคำสั่งซื้อเสร็จสมบูรณ์ ระบบจะส่งอีเมลยืนยันการชำระเงินไปที่อีเมลของคุณ
                            </li>

                        </ul>


                        <div class="mt-5 text-secondary">โปรดตรวจสอบรายละเอียดให้ถูกต้องก่อนทำการชำระเงิน</div>

                    </div>
                </div>


                <div class="w-100 btn btn-primary mb-3" @click="close">
                    ปิด
                </div>

            </div>
        </div>

    </div>
</template>
<script>

  export default {
    name: 'howtopayment',
    props: {
      data: Object
    },
    methods: {
      close() {
        this.$emit("closePanel", {});
      },
    },
  };
</script>


<style lang="scss" scoped>

</style>
