let config;

if (process.env.NODE_ENV === 'production') {
    config = {
        API_URL: 'https://qr-payment.stockradars.co/service/v1.3',
        GIFT_URL: 'https://radarspoint.com/giftforyou/card',
        GBP_URL: 'https://api.gbprimepay.com/v2',
        GBP_PUBLIC_KEY : '0t4RVIesno40jo3UUGkLaCwgQAXNNa5v',
        GBP_TOKEN : 'MHQ0UlZJZXNubzQwam8zVVVHa0xhQ3dnUUFYTk5hNXY6'
    };
} else {
    config = {
        API_URL: 'https://qr-payment.stockradars.co/service-dev/v1.3',
        GIFT_URL: 'https://radarspoint.com/giftforyou-dev/card',
        GBP_URL: 'https://api.globalprimepay.com/v2',
        GBP_PUBLIC_KEY : 'PAt6jbrybpe9JHS9YjzQQ5sknubqjLEn',
        GBP_TOKEN : 'UEF0NmpicnlicGU5SkhTOVlqelFRNXNrbnVicWpMRW46'
    };
}

module.exports = config;

