import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    {
        path: '/product-detail',
        name: 'product-detail',
        component: () => import('../views/Product-detail.vue')
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/Payment.vue')
    },
    {
        path: '/credit-card',
        name: 'credit-card',
        component: () => import('../views/Credit-card.vue')
    },
    {
        path: '/success',
        name: 'success',
        component: () => import('../views/Success.vue')
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import('../views/Callback.vue')
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('../views/Message.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
