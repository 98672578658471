import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.scss'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueSlideoutPanel from 'vue2-slideout-panel';
import VueClipboard from 'vue-clipboard2'
import Howtopayment from "./components/Howtopayment";
import VueCookies from 'vue-cookies'
import VueMask from 'v-mask'
import VueNumberInput from '@chenfengyuan/vue-number-input';

import './filters/number'

Vue.config.productionTip = false;

Vue.component(VueQrcode.name, VueQrcode);
Vue.component(Howtopayment.name, Howtopayment);
Vue.use(VueClipboard)
Vue.use(VueSlideoutPanel);
Vue.use(VueCookies, {expire: '1m'})
Vue.use(VueMask);
Vue.use(VueNumberInput);

import {
    GET_USER_INFO
} from "./store/actions.type";

async function init() {
    await store.dispatch(GET_USER_INFO);

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
}

init();
