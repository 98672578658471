import {
    CREATE_FULL_PAYMENT, GET_CARD_TOKEN,
    GET_TOKEN_GBP, SET_CARD_TOKEN,
} from "./actions.type";
import {gbpService} from "../service/gbp";

function initState() {
    return {
        token: "",
        gbpReferenceNo: "",
        cards: [],
    }
}

const state = initState();

const actions = {
    async [GET_TOKEN_GBP]({commit}, data) {
        let response = await gbpService.generateToken(data);
        if (response.resultCode === '00') {
            commit(GET_TOKEN_GBP, response.card.token);
        }
        return response;
    },
    async [CREATE_FULL_PAYMENT]({commit}, data) {
        let response = await gbpService.createFullPayment(data);
        if (response.status === 'yes') {
            commit(CREATE_FULL_PAYMENT, response.result.gbpReferenceNo);
        }
        return response;
    },
    async [GET_CARD_TOKEN]({commit}, data) {
        let response = await gbpService.getCardToken(data);
        if (response.status === 'yes') {
            commit(GET_CARD_TOKEN, response.result);
        }
        return response;
    },
    async [SET_CARD_TOKEN]({commit}, data) {
        return await gbpService.setCardToken(data);
    },
};

const mutations = {
    [GET_TOKEN_GBP](state, token) {
        state.token = token;
    },
    [CREATE_FULL_PAYMENT](state, gbpReferenceNo) {
        state.gbpReferenceNo = gbpReferenceNo;
    },
    [GET_CARD_TOKEN](state, cards) {
        state.cards = cards || [];
    },
};

const getters = {};

export default {
    state,
    actions,
    mutations,
    getters
};
