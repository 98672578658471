import axios from 'axios';
import * as queryString from "query-string";
import {GBP_URL, API_URL, GBP_TOKEN} from "../config/constant";

export const gbpService = {
    async generateToken(data) {
        let config = {
            method: 'POST',
            url: `${GBP_URL}/tokens`,
            headers: {
                'Authorization': `Basic ${GBP_TOKEN}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data)
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    },

    async createFullPayment(data) {
        let config = {
            method: 'POST',
            url: `${API_URL}/transaction/create_full_payment`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            validateStatus: () => true,
            data: queryString.stringify(data)
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    },

    async getCardToken(data) {
        let config = {
            method: 'GET',
            url: `${API_URL}/user/get_card_token`,
            params: data
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    },


    async setCardToken(data) {
        let config = {
            method: 'POST',
            url: `${API_URL}/user/set_card_token`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: queryString.stringify(data)
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    },
};
