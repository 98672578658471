import axios from 'axios';
import * as queryString from "query-string";
import {API_URL} from "../config/constant";

export const paymentService = {

    async getItemById(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/item/id/${id}`,
            validateStatus: () => true
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (!error.response) {
                    return false;
                } else {
                    return error.response.data
                }
            });
    },

    async generateTransaction(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/transaction/create_qr`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((e) => {
                return e.response.data
            });
    },

    async generateTransactionGBP(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/transaction/create_gbprime_qrcash`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((e) => {
                return e.response.data
            });
    },

    checkTransactionStatus(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/transaction/status/?txn=${data.transactionId}&phone=${data.phone}`,
            validateStatus: () => true
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (!error.response) {
                    return false;
                } else {
                    return error.response.data
                }
            });
    },

    async getRedeemCode(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/item/get_redeem_code`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((e) => {
                return e.response.data
            });
    },

    async checkPermission(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/item/check_user_permission/${data.id}?pbid=${data.pbid}`,
            validateStatus: () => true
        })
            .then(function (response) {
                return response.data
            }).catch((e) => {
                return e.response.data
            });
    },
};
